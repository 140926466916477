import { type VariantProps, cva } from "class-variance-authority";
import { cx } from "class-variance-authority";
import { type HTMLAttributes, forwardRef } from "react";

const chipVariants = cva(
    "inline-flex items-center rounded-lg border px-3 py-2 transition-colors",
    {
        variants: {
            variant: {
                default:
                    "bg-blue-grey-50 text-blue-grey-500 hover:border-blue-grey-100",
                selected: "border-solid border-primary-500 bg-primary-50",
            },
            size: {
                md: "typography-sub",
                lg: "typography-main",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    },
);

export interface ChipProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof chipVariants> {}

const Chip = forwardRef<HTMLDivElement, ChipProps>(
    ({ className, variant, size, ...props }, ref) => (
        <div
            ref={ref}
            className={cx(chipVariants({ variant, size, className }))}
            {...props}
        />
    ),
);
Chip.displayName = "Chip";

export { Chip, chipVariants };
