import * as Dialog from "@radix-ui/react-dialog";
import { cx } from "class-variance-authority";
import { type ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import { Button, type ButtonProps } from "./Button";

type ButtonVariants = ButtonProps["variant"];
type Props<TOk, TCancel> = {
    title?: ReactNode;
    loading?: boolean;
    okVariant?: ButtonVariants;
    okText?: ReactNode;
    onOkClick?: () => Promise<TOk>;
    cancelVariant?: ButtonVariants;
    cancelText?: ReactNode;
    onCancelClick?: () => Promise<TCancel>;
    description?: ReactNode;
    justifyButtons?: "left" | "center" | "right";
};

type InnerProps<TOk, TCancel> = Props<TOk, TCancel> & {
    resolve: () => void;
    container: HTMLElement;
};

const ConfirmationDialog = <TOk, TCancel>(
    props: InnerProps<TOk, TCancel>,
): JSX.Element => {
    const [open, setOpen] = useState(true);
    let justifyButtons = "justify-end";
    if (props.justifyButtons === "center") justifyButtons = "justify-center";
    else if (props.justifyButtons === "left") justifyButtons = "justify-start";
    return (
        <Dialog.Root
            open={open}
            onOpenChange={async (o) => {
                setOpen(o);
                if (!o) {
                    await props.onCancelClick?.();
                    props.resolve();
                }
            }}
        >
            <Dialog.Portal container={props.container}>
                <Dialog.Overlay className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
                <Dialog.Content className="fixed inset-1/2 z-50 h-max max-h-screen w-full max-w-prose -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-white p-6 text-blue-grey-900 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
                    <Dialog.Title className="typography-h4 font-bold text-blue-grey-900">
                        {props.title ?? "Are you sure?"}
                    </Dialog.Title>
                    <Dialog.Description className="typography-sub my-4 text-blue-grey-900">
                        {props.description}
                    </Dialog.Description>
                    <div className={cx("flex gap-2", justifyButtons)}>
                        <Button
                            type="button"
                            className="min-w-20"
                            variant={props.cancelVariant ?? "outlined"}
                            disabled={props.loading}
                            onClick={async () => {
                                await props.onCancelClick?.();
                                props.resolve();
                                setOpen(false);
                            }}
                        >
                            {props.cancelText ?? "Cancel"}
                        </Button>
                        <Button
                            type="button"
                            className="min-w-20"
                            variant={props.okVariant ?? "primary"}
                            disabled={props.loading}
                            onClick={async () => {
                                await props.onOkClick?.();
                                props.resolve();
                                setOpen(false);
                            }}
                        >
                            {props.okText ?? "Ok"}
                        </Button>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export const confirm = async <TOk, TCancel>(
    props: Props<TOk, TCancel>,
): Promise<void> => {
    const wrapper = document.body.appendChild(document.createElement("div"));

    await new Promise<void>((resolve) => {
        ReactDOM.render(
            <ConfirmationDialog
                resolve={resolve}
                container={wrapper}
                {...props}
            />,
            wrapper,
        );
    });

    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => {
            if (wrapper.parentNode) {
                wrapper.parentNode.removeChild(wrapper);
            }
        });
    }, 300);
};
